import React from 'react';
import { MessageSquare, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export function LoginForm() {
  const navigate = useNavigate();
  const { signInWithDiscord, loading } = useAuth();

  return (
    <div className="min-h-screen flex items-center justify-center p-4 bg-gray-900">
      {/* Animated Background */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -inset-[10px] opacity-30">
          <div className="absolute top-1/4 left-1/4 w-[500px] h-[500px] rounded-full bg-gradient-to-r from-blue-500/20 to-cyan-500/20 blur-3xl animate-pulse"></div>
          <div className="absolute bottom-1/4 right-1/4 w-[400px] h-[400px] rounded-full bg-gradient-to-r from-cyan-500/20 to-blue-500/20 blur-3xl animate-pulse delay-300"></div>
        </div>
      </div>

      <div className="w-full max-w-md space-y-8">
        <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-xl border border-blue-500/20 relative overflow-hidden">
          {/* Background Animation */}
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 to-cyan-500/5 animate-gradient-x"></div>

          {/* Content */}
          <div className="relative">
            <div className="text-center mb-8">
              <div className="flex justify-center mb-6">
                <img 
                  src="/logo.png"
                  alt="Logo"
                  className="w-20 h-20 object-contain"
                />
              </div>
              <h2 className="text-2xl font-bold text-white mb-2">
                Benvenuto Staff
              </h2>
              <p className="text-gray-400">
                Accedi per gestire il locale
              </p>
            </div>

            <button
              onClick={signInWithDiscord}
              disabled={loading}
              className="w-full flex items-center justify-center gap-2 px-4 py-4 bg-gradient-to-r from-blue-500 to-cyan-500 text-white rounded-lg hover:from-blue-600 hover:to-cyan-600 transition-all duration-300 transform hover:scale-[0.98] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100"
            >
              <MessageSquare className="h-5 w-5" />
              {loading ? 'Accesso in corso...' : 'Continua con Discord'}
            </button>
          </div>
        </div>

        <button
          onClick={() => navigate('/')}
          className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-gray-800/50 text-gray-300 rounded-lg hover:bg-gray-700/50 transition-all duration-200 backdrop-blur-sm"
        >
          <ArrowLeft className="h-5 w-5" />
          Torna alla Home
        </button>
      </div>
    </div>
  );
}