import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import { 
  TrendingUp, DollarSign, Users, ChartBar, 
  Calendar, Clock, Award, Target, Package, ShoppingCart
} from 'lucide-react';
import toast from 'react-hot-toast';

interface EmployeePerformance {
  name: string;
  total_sales: number;
  orders_count: number;
  average_order: number;
}

interface DashboardStats {
  totalRevenue: number;
  totalOrders: number;
  averageOrderValue: number;
  topProducts: Array<{
    name: string;
    quantity: number;
    revenue: number;
  }>;
  recentActivity: Array<{
    id: number;
    employee_name: string;
    total: number;
    created_at: string;
  }>;
}

export function Dashboard() {
  const [userName, setUserName] = useState('');
  const [employees, setEmployees] = useState<EmployeePerformance[]>([]);
  const [stats, setStats] = useState<DashboardStats>({
    totalRevenue: 0,
    totalOrders: 0,
    averageOrderValue: 0,
    topProducts: [],
    recentActivity: []
  });
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({
    daily_target: 500,
    weekly_target: 2500,
    monthly_target: 10000,
  });

  useEffect(() => {
    fetchUserName();
    fetchData();
    fetchSettings();
  }, []);

  const fetchUserName = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Ottieni il profilo da Supabase
      const { data: profile } = await supabase
        .from('profiles')
        .select('name')
        .eq('id', user.id)
        .single();

      if (profile?.name) {
        setUserName(profile.name);
      } else {
        // Fallback al nome Discord se il nome personalizzato non è impostato
        const discordName = user.user_metadata?.full_name || 
                          user.user_metadata?.name || 
                          user.user_metadata?.custom_claims?.global_name;
        setUserName(discordName || 'Utente');
      }
    } catch (error) {
      console.error('Error fetching user name:', error);
      setUserName('Utente');
    }
  };

  const fetchSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('dashboard_settings')
        .select('*')
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      if (data) {
        setSettings(data);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const fetchData = async () => {
    try {
      // Fetch employee performance
      const { data: employeesData, error: employeesError } = await supabase
        .from('profiles')
        .select(`
          name,
          sales (
            total,
            created_at
          )
        `);

      if (employeesError) throw employeesError;

      // Process employee data
      const processedEmployees = employeesData.map(emp => ({
        name: emp.name,
        total_sales: emp.sales?.reduce((sum: number, sale: any) => sum + (sale.total || 0), 0) || 0,
        orders_count: emp.sales?.length || 0,
        average_order: emp.sales?.length ? 
          (emp.sales.reduce((sum: number, sale: any) => sum + (sale.total || 0), 0) / emp.sales.length) : 0
      }));

      setEmployees(processedEmployees);

      // Fetch overall statistics
      const { data: salesData, error: salesError } = await supabase
        .from('sales')
        .select(`
          id,
          total,
          created_at,
          profiles (name)
        `)
        .order('created_at', { ascending: false })
        .limit(10);

      if (salesError) throw salesError;

      // Fetch top products
      const { data: productsData, error: productsError } = await supabase
        .from('sale_items')
        .select(`
          quantity,
          price,
          products (name)
        `);

      if (productsError) throw productsError;

      // Process statistics
      const totalRevenue = salesData?.reduce((sum, sale) => sum + sale.total, 0) || 0;
      const totalOrders = salesData?.length || 0;
      const averageOrderValue = totalOrders > 0 ? totalRevenue / totalOrders : 0;

      // Process top products
      const productStats = productsData.reduce((acc: any, item) => {
        const productName = item.products.name;
        if (!acc[productName]) {
          acc[productName] = { quantity: 0, revenue: 0 };
        }
        acc[productName].quantity += item.quantity;
        acc[productName].revenue += item.quantity * item.price;
        return acc;
      }, {});

      const topProducts = Object.entries(productStats)
        .map(([name, stats]: [string, any]) => ({
          name,
          quantity: stats.quantity,
          revenue: stats.revenue
        }))
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 5);

      // Process recent activity
      const recentActivity = salesData.map(sale => ({
        id: sale.id,
        employee_name: sale.profiles?.name || 'Unknown',
        total: sale.total,
        created_at: sale.created_at
      }));

      setStats({
        totalRevenue,
        totalOrders,
        averageOrderValue,
        topProducts,
        recentActivity
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Errore nel caricamento dei dati');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-2rem)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Welcome Section */}
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-cyan-500/10 rounded-2xl blur-xl"></div>
        <div className="relative bg-gray-900/50 backdrop-blur-sm rounded-xl p-8 border border-blue-500/20">
          <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-400 to-cyan-500 text-transparent bg-clip-text mb-2">
            Bentornato, {userName}
          </h1>
          <p className="text-gray-400">Ecco la tua dashboard personale</p>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Today's Sales */}
        <div className="bg-gray-900 rounded-xl p-6 border border-blue-500/20 hover:border-blue-500/40 transition-colors">
          <div className="flex items-center justify-between mb-4">
            <div className="p-2 bg-blue-500/10 rounded-lg">
              <DollarSign className="h-6 w-6 text-blue-400" />
            </div>
          </div>
          <h3 className="text-gray-400 mb-2">Vendite Giornaliere</h3>
          <p className="text-2xl font-bold text-blue-400">
            ${stats.totalRevenue.toLocaleString()}
          </p>
          <div className="mt-4 pt-4 border-t border-gray-800">
            <div className="flex justify-between items-center text-sm">
              <span className="text-gray-500">Obiettivo</span>
              <span className="text-cyan-400">${settings.daily_target.toLocaleString()}</span>
            </div>
            <div className="mt-2 w-full bg-gray-800 rounded-full h-2">
              <div
                className="bg-gradient-to-r from-blue-500 to-cyan-500 rounded-full h-2 transition-all duration-500"
                style={{ width: `${Math.min(((stats.totalRevenue / settings.daily_target) * 100), 100)}%` }}
              ></div>
            </div>
          </div>
        </div>

        {/* Weekly Sales */}
        <div className="bg-gray-900 rounded-xl p-6 border border-cyan-500/20 hover:border-cyan-500/40 transition-colors">
          <div className="flex items-center justify-between mb-4">
            <div className="p-2 bg-cyan-500/10 rounded-lg">
              <TrendingUp className="h-6 w-6 text-cyan-400" />
            </div>
          </div>
          <h3 className="text-gray-400 mb-2">Vendite Settimanali</h3>
          <p className="text-2xl font-bold text-cyan-400">
            ${stats.totalRevenue.toLocaleString()}
          </p>
          <div className="mt-4 pt-4 border-t border-gray-800">
            <div className="flex justify-between items-center text-sm">
              <span className="text-gray-500">Obiettivo</span>
              <span className="text-cyan-400">${settings.weekly_target.toLocaleString()}</span>
            </div>
            <div className="mt-2 w-full bg-gray-800 rounded-full h-2">
              <div
                className="bg-gradient-to-r from-blue-500 to-cyan-500 rounded-full h-2 transition-all duration-500"
                style={{ width: `${Math.min(((stats.totalRevenue / settings.weekly_target) * 100), 100)}%` }}
              ></div>
            </div>
          </div>
        </div>

        {/* Monthly Sales */}
        <div className="bg-gray-900 rounded-xl p-6 border border-blue-500/20 hover:border-blue-500/40 transition-colors">
          <div className="flex items-center justify-between mb-4">
            <div className="p-2 bg-blue-500/10 rounded-lg">
              <Calendar className="h-6 w-6 text-blue-400" />
            </div>
          </div>
          <h3 className="text-gray-400 mb-2">Vendite Mensili</h3>
          <p className="text-2xl font-bold text-blue-400">
            ${stats.totalRevenue.toLocaleString()}
          </p>
          <div className="mt-4 pt-4 border-t border-gray-800">
            <div className="flex justify-between items-center text-sm">
              <span className="text-gray-500">Obiettivo</span>
              <span className="text-cyan-400">${settings.monthly_target.toLocaleString()}</span>
            </div>
            <div className="mt-2 w-full bg-gray-800 rounded-full h-2">
              <div
                className="bg-gradient-to-r from-blue-500 to-cyan-500 rounded-full h-2 transition-all duration-500"
                style={{ width: `${Math.min(((stats.totalRevenue / settings.monthly_target) * 100), 100)}%` }}
              ></div>
            </div>
          </div>
        </div>

        {/* Team Members */}
        <div className="bg-gray-900 rounded-xl p-6 border border-cyan-500/20 hover:border-cyan-500/40 transition-colors">
          <div className="flex items-center justify-between mb-4">
            <div className="p-2 bg-cyan-500/10 rounded-lg">
              <Users className="h-6 w-6 text-cyan-400" />
            </div>
          </div>
          <h3 className="text-gray-400 mb-2">Membri del Team</h3>
          <p className="text-2xl font-bold text-cyan-400">
            {employees.length}
          </p>
          <div className="mt-4 pt-4 border-t border-gray-800">
            <div className="text-sm text-gray-500">
              Ordini Totali: {stats.totalOrders}
            </div>
          </div>
        </div>
      </div>

      {/* Additional Stats */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Top Products */}
        <div className="bg-gray-900 rounded-xl p-6 border border-blue-500/20">
          <h3 className="text-xl font-bold text-blue-400 mb-6 flex items-center gap-2">
            <Package className="h-5 w-5" />
            Prodotti Più Venduti
          </h3>
          <div className="space-y-4">
            {stats.topProducts.map((product, index) => (
              <div key={product.name} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 flex items-center justify-center bg-gradient-to-br from-blue-500/10 to-cyan-500/10 rounded-lg text-cyan-400 font-medium">
                    {index + 1}
                  </div>
                  <div>
                    <p className="text-gray-300">{product.name}</p>
                    <p className="text-sm text-gray-500">
                      {product.quantity} unità vendute
                    </p>
                  </div>
                </div>
                <p className="text-cyan-400 font-medium">
                  ${product.revenue.toLocaleString()}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Recent Activity */}
        <div className="bg-gray-900 rounded-xl p-6 border border-cyan-500/20">
          <h3 className="text-xl font-bold text-cyan-400 mb-6 flex items-center gap-2">
            <Clock className="h-5 w-5" />
            Attività Recenti
          </h3>
          <div className="space-y-4">
            {stats.recentActivity.map((activity) => (
              <div key={activity.id} className="flex items-center justify-between">
                <div>
                  <p className="text-gray-300">{activity.employee_name}</p>
                  <p className="text-sm text-gray-500">
                    {new Date(activity.created_at).toLocaleString()}
                  </p>
                </div>
                <p className="text-cyan-400 font-medium">
                  ${activity.total.toLocaleString()}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}