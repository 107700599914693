import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    checkUser();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state changed:', event, session);
      
      if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        setIsAuthenticated(true);
        if (session?.user) {
          await handleSignIn(session.user);
        }
      } else if (event === 'SIGNED_OUT') {
        setIsAuthenticated(false);
        navigate('/');
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [navigate]);

  const checkUser = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user) {
        setIsAuthenticated(true);
        await handleSignIn(session.user);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Error checking auth:', error);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async (user: any) => {
    if (!user) return;

    try {
      // Get Discord metadata
      const discordName = user.user_metadata?.name || 
                         user.user_metadata?.full_name || 
                         user.user_metadata?.custom_claims?.global_name || 
                         'Unknown';

      // Check if profile exists
      const { data: existingProfile } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (!existingProfile) {
        // Create new profile if it doesn't exist
        const { error: profileError } = await supabase
          .from('profiles')
          .insert([{
            id: user.id,
            name: discordName,
            discord_name: discordName,
            role: 'employee',
            created_at: new Date().toISOString(),
            on_duty: false
          }]);

        if (profileError) throw profileError;
      }

      navigate('/dashboard');
    } catch (error) {
      console.error('Profile error:', error);
      toast.error('Errore durante la configurazione del profilo');
    }
  };

  const signInWithDiscord = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'discord',
        options: {
          redirectTo: `${window.location.origin}/dashboard`,
          scopes: 'identify email guilds',
          queryParams: {
            prompt: 'consent'
          }
        }
      });

      if (error) throw error;
      if (!data.url) throw new Error('No redirect URL provided');

      // Redirect manually to ensure proper flow
      window.location.href = data.url;
    } catch (error) {
      console.error('Discord login error:', error);
      toast.error('Errore durante l\'accesso con Discord');
    }
  };

  const signOut = async () => {
    try {
      // First update duty status if needed
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        await supabase
          .from('profiles')
          .update({ on_duty: false })
          .eq('id', user.id);
      }

      // Then sign out
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      setIsAuthenticated(false);
      toast.success('Disconnessione effettuata con successo');
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Errore durante la disconnessione');
    }
  };

  return {
    isAuthenticated,
    loading,
    signInWithDiscord,
    signOut
  };
}