import React from 'react';
import { Sparkles } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function Home() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-900 via-gray-900 to-gray-800">
      <div className="flex-1 flex flex-col items-center justify-center px-4 relative overflow-hidden">
        {/* Animated Background */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -inset-[10px] opacity-50">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[500px] h-[500px] rounded-full bg-gradient-to-r from-blue-500/20 to-cyan-500/20 blur-3xl animate-pulse"></div>
          </div>
        </div>

        <div className="w-full max-w-4xl mx-auto text-center space-y-12 relative z-10">
          <div className="space-y-8">
            {/* Logo */}
            <div className="flex justify-center">
              <img 
                src="/logo.png"  
                alt="Logo"
                className="w-24 h-24 object-contain"
              />
            </div>
            
            <div className="space-y-6">
              <h1 className="text-7xl font-bold text-white">
                <span className="bg-gradient-to-r from-blue-400 via-cyan-400 to-blue-500 text-transparent bg-clip-text animate-gradient-x">
                  FiveM Local
                </span>
              </h1>
              <p className="text-2xl text-cyan-300/80 font-light">
                Sistema di Gestione Staff
              </p>
            </div>
          </div>

          <div className="flex justify-center pt-8">
            <button 
              onClick={() => navigate('/login')}
              className="group relative inline-flex items-center justify-center overflow-hidden rounded-xl bg-gradient-to-r from-blue-500 via-cyan-500 to-blue-500 p-0.5 font-bold text-white hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-800 animate-gradient-x"
            >
              <span className="relative flex items-center gap-3 rounded-lg px-8 py-4 transition-all duration-300 ease-out bg-gray-900/90 group-hover:bg-opacity-0">
                <Sparkles className="h-5 w-5" />
                Accedi
              </span>
            </button>
          </div>
        </div>
      </div>

      <footer className="relative mt-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-cyan-500/5 to-blue-500/5 animate-gradient-x"></div>
        <div className="relative border-t border-blue-500/10">
          <div className="max-w-4xl mx-auto py-8 px-4">
            <div className="text-center space-y-4">
              <div className="flex items-center justify-center space-x-3">
                <div className="w-2 h-2 rounded-full bg-gradient-to-r from-blue-400 to-cyan-500 animate-pulse"></div>
                <div className="w-2 h-2 rounded-full bg-gradient-to-r from-cyan-400 to-blue-500 animate-pulse delay-75"></div>
                <div className="w-2 h-2 rounded-full bg-gradient-to-r from-blue-500 to-cyan-400 animate-pulse delay-150"></div>
              </div>
              <p className="text-gray-400 font-medium">
                Creato con <span className="bg-gradient-to-r from-blue-400 to-cyan-500 text-transparent bg-clip-text">❤️</span> da R0yce
              </p>
              <p className="text-sm text-gray-500">
                © {new Date().getFullYear()} FiveM Local. Tutti i diritti riservati.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}